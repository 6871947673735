import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/app';

ReactDOM.render(
  <App></App>,
  document.getElementById('root')
);

const welcome = () => console.log('Welcome to Gravity 🚀')
welcome('de529c70-eb80-4dfb-9540-5075db7545bf')