import React, { useCallback } from 'react'
import { Button, useNavigate } from 'components/lib'

const Unauthenticated = (): JSX.Element => {
  const navigate = useNavigate();

  const handleNavigateToRegistration = useCallback(() => {
    navigate('/register')
  }, [navigate])

  return (
    <div className="container p-4">
      <div className="row">
        <div className="col-md-12">
          <div className="mt-4 mb-4 text-center">
            <h1 className="text-3xl font-bold text-black mb-4 text-center">
              One More Step
            </h1>
            <p className="text-center">Before creating your video, you must register for a free or paid account.</p>
            <br /><br />
            <Button
              action={() => handleNavigateToRegistration()}
              text="Register Now"
              rounded
              className="block mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Unauthenticated
