import { Seo, useNavigate } from "components/lib";
import PlayVideoComp from "components/playVideo/playVideo";
import React, { useState, useEffect } from "react";
import loader from "./image/loading.png";
import Axios from "axios";
import stl from './createVideo.module.scss'
import { getEnv } from "app/util";
const Settings = require('../../../../src/settings.json')

let timeInterval = null;

function Preview() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('')
  const [progressText, setProgressText] = useState('')

  const validateUrl = (url) => {
    if (url) {
      // eslint-disable-next-line no-useless-escape
      let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      var regex = new RegExp(expression);
      if (url.match(regex)) {
        return true;
      } else {
        return false;
      }
    }
  }

  const getProgress = async () => {
    let params = new URLSearchParams(window.location.search);
    let uniqueId = params.get('uniqueid');
    if (!uniqueId) {
      navigate('/dashboard')
    }
    const baseUrl = Settings[getEnv()].video_server_url;
    let res = await Axios({
      method: 'GET',
      url: `get_progress?uniqueid=${uniqueId}`,
      baseURL: `${baseUrl}`,
    });
    let url = res.data;
    if (validateUrl(url)) {
      setUrl(res.data)
      setLoading(false)
    } else {
      setProgressText(res.data)
    }
  }
  useEffect(() => {
    if (url.length && timeInterval) {
      clearInterval(timeInterval)
    }
  }, [url])

  useEffect(() => {
    let chromeUrl = window.location.href.split("url=")[1];
    if (validateUrl(chromeUrl)) {
      setUrl(chromeUrl)
      setLoading(false)
    } else {
      if (url.length && timeInterval) {
        clearInterval(timeInterval)
      } else {
        if (!timeInterval) {
          getProgress()
          timeInterval = setInterval(() => {
            getProgress()
          }, 7000)
        }
      }
    }
    return () => {
      clearInterval(timeInterval)
      timeInterval = null
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <Seo
        title="Preview Your Video"
        description="check out your video in your mail"
      />
      {loading && (
        <div className="flex align-center justify-column mt-10 mb-4 container-padding">
          <div
            className={stl.loadingContainer}
            style={{
              width: "794px",
              height: "359px",
              border: "2px solid #000",
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 25
            }}
          >
            {/* <Loader videoLoader></Loader> */}
            <div style={{ width: 60, height: 60 }}>
              <img src={loader} className={'animate-loading'} alt="Orbit Spinner" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 10 }}>
              <p className={stl.loadingText}>Loading </p>
              <p className={stl.loadingText}>{progressText.length ? progressText : 'The video is in the make. You can watch your video soon.'} </p>
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <PlayVideoComp
          url={url}
          buttonText="Create Again"
          goto="/dashboard"
          hideText={true}
        ></PlayVideoComp>
      )}
    </>
  );
}

export default Preview;
