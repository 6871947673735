import axios from "axios";
import React from "react";

function PlanPage() {
  const handleSubmit = (planId) => {
    axios
      .post("http://localhost:4242/create-checkout-session", {
        priceId: planId,
      })
      .then((res) => {
        window.open(res.data.url, "_blank");
      });
  };
  return (
    <div>
      <button onClick={() => handleSubmit("price_1L7ul3I0TP8pHcxjDXA1DxHa")}>
        Basic
      </button>
      <br />
      <button onClick={() => handleSubmit("price_1L7yzoI0TP8pHcxj7Ex6JExZ")}>
        Pro
      </button>
    </div>
  );
}

export default PlanPage;
