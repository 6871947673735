/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React, { useContext } from 'react';
import {
  Animate,
  // Chart,
  // Grid,
  // Card,
  // ProgressBar,
  // Table,
  Seo,
  // Stat,
  // Message,
  // useAPI,
  Avtarcard,
  Link,
  AuthContext,
} from 'components/lib';

export function Dashboard(props) {
  const context = useContext(AuthContext);
  const paidUser = context.user.plan === 'paid';
  // const stats = useAPI("/api/demo/stats");
  // const progress = useAPI("/api/demo/progress");
  // const table = useAPI("/api/demo/users/list");
  // const userChart = useAPI("/api/demo/users/types");
  // const revenueChart = useAPI("/api/demo/revenue");

  return (
    <Animate type="pop">
      <Seo title="Dashboard" description="Animatr Dashboard" />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fffff',
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        {/* <Message
          closable
          title="Welcome to Gravity!"
          text="This is a sample dashboard to get you started. Please read the documentation to learn how to build your own features."
          type="info"
        /> */}
        <Avtarcard
          restrictWidth
          center
          className="mt-16"
          title={'Choose Your Avatar : '}
          label1={
            "Your avatar comes with a voice, hover over the audio icon in the upper right side of the picture to listen to it the avatar's voice."
          }
          title2={'Create your script for the video :'}
          label2={
            'You can type or paste your text of max. 100 words , which will be transformed into a video'
          }
          goto="/preview"
          isPaidUser={paidUser}
          user={context.user}
        />
        {!paidUser && (
          <div
            style={{
              paddingTop: 40,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontWeight: '600',
                fontSize: 20,
                color: '#231F20',
                marginBottom: 8,
              }}
            >
              You can only generate a 10 second video.
            </p>
            <Link url="/account/billing" text="Upgrade now" />
          </div>
        )}

        {/* <Grid cols="4">
          <Stat
            loading={stats?.loading}
            value={stats?.data?.users}
            label="users"
            icon="users"
          />
          <Stat
            loading={stats?.loading}
            value={stats?.data?.active}
            label="active"
            icon="check"
          />
          <Stat
            loading={stats?.loading}
            value={stats?.data?.churned}
            label="churned"
            icon="refresh-cw"
          />
          <Stat
            loading={stats?.loading}
            value={stats?.data?.latest}
            label="this month"
            icon="calendar"
            change="5%"
          />
        </Grid> */}

        {/* <Card name="revenues" title="Revenue">
          <Chart
            type="line"
            legend
            loading={revenueChart.loading}
            data={revenueChart.data}
            color={["red", "blue"]}
          />
        </Card> */}

        {/* <Grid cols="2">
          <Card title="Goals" loading={progress.loading}>
            {progress?.data?.map((item) => {
              return (
                <ProgressBar
                  key={item.label}
                  label={item.label}
                  progress={item.value}
                />
              );
            })}
          </Card>
          <Card title="User Types">
            <Chart
              type="pie"
              legend={true}
              data={userChart.data}
              loading={userChart.loading}
              color="purple"
            />
          </Card>
        </Grid> */}

        {/* <Card title="Users" last>
          <Table
            search
            data={table.data}
            loading={table.loading}
            badge={{ col: "plan", color: "blue" }}
          ></Table>
        </Card> */}
      </div>
    </Animate>
  );
}
