import React, { useState } from "react";
import { Loader, useNavigate, FormWithTwoTextArea, Link } from "components/lib";
import Slider from "react-slick";
import Axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import stl from "./avatarform.module.scss";
import {v4 as uuidv4} from "uuid";


const Settings = require('../../../src/settings.json')

export function AvatarForm(props) {
    const navigate = useNavigate();
    const requestCreateVideo = async (content, avatar) => {
        let uniqueId =uuidv4()
        console.log(content.length)
        if (content.length >= 21) {
            navigate(`${props.goto}?uniqueid=${uniqueId}&avatar=${avatar}&content=${content}`);
        }
    }
    return (
            <React.Fragment>
            <FormWithTwoTextArea
                data={{
                    content: {
                        videoTitle: "Write your prompt",
                        videoType: "textarea",
                        videoMaxChars: 100,
                        required: true,
                        class: "rounded-lg",
                        errorMessage: "Please enter video script",
                        scriptText: true,
                        placeholderVideo:
                            "Example : Hi there, this time I am reaching out to you with a video, cool eh? I wanted to bring your attention to a new product we introduced last week, and it might just be the perfect fit for your company.....",
                        soundTitle: "Write your voice text",
                        soundType: "textarea",
                        soundMaxChars: 50,
                        placeholderSound:
                            "Example : Hi there, this time I am reaching out to you with a video, cool eh? I wanted to bring your attention to a new product we introduced last week, and it might just be the perfect fit for your company.....",
                    },
                    avatar: {
                        type: "hidden",
                        value: 'select avatar',
                    }
                }}
                // url="/api/account"
                method="POST"
                buttonText="Create Video"
                languageButtonText="Choose Language"
                callback={(data) => {
                    requestCreateVideo(data.content, '')
                }}
                buttonCustomClass={stl.buttonContainer}
            />
             <div className={stl.linkDescriptive}>
              Already have an account? <Link className={stl.linkContainer} url="/signin" text="Sign In" />
            </div>
            </React.Fragment>
    );
}
