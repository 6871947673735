/***
*
*   BILLING 
*   Update card details
*
**********/

import React, { useState, useEffect } from 'react';
import { Card, CreditCard, PaymentForm, useAPI } from 'components/lib';
import ArrowBack from "./images/arrow-back.png";
import stl from './card.module.scss'

export function BillingCard(props) {

  const [card, setCard] = useState(null);
  const data = useAPI('/api/account/card');

  useEffect(() => {

    if (data.data)
      setCard(data.data)

  }, [data.data])

  const handleCallBack = (data) => {
    props.callBack(data)
  }

  return (
    <Card center marginCenter className="form-width form-border" loading={data.loading}>

      {card &&
        <CreditCard
          brand={card.brand}
          last_four={card.last4}
          expires={`${card.exp_month}/${card.exp_year}`}
        />
      }
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, marginBottom: 10,cursor : 'pointer' }}
        onClick={props.onBack}
      >
        <img src={ArrowBack} style={{ width: 10, height: 10 }} alt={'arrow-back'} />
        <p style={{
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 800,
          fontSize: "16px",
          color: "#231F20"
        }}>Back</p>
      </div>
      <PaymentForm
        className={stl.formContainer}
        data={{
          token: {
            label: 'Update Your Card',
            type: 'creditcard',
            required: true
          }
        }}
        callback={res => handleCallBack(res)}
        buttonText='Add Card'
        buttonColor={'#fffff'}
        hideStripeButton={true}
      />

    </Card>
  )
}