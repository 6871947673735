import {
  Animate,
  AuthContext,
  ViewContext,
  Form,
  Card,
  Row,
  Seo,
  useLocation,
  useNavigate,
  Button,
} from "components/lib";
import React, { useContext, useState, useEffect } from "react";

import stl from "./authentication.module.scss";


function LoginPage() {
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [form] = useState({
    email: {
      label: "Email Address",
      type: "email",
      class: "rounded-lg",
      required: true,
    },
    password: {
      label: "Password",
      type: "password",
      required: true,
      class: "rounded-lg",
      complexPassword: true,
    },
    forgotpassword: {
      type: "link",
      url: "/forgotpassword",
      text: "Forgot your password?",
    },
  });

  useEffect(() => {
    // was an error message passed from the server router?
    const qs = location.search;

    if (qs.includes("error")) {
      const msg = decodeURIComponent(qs.substring(qs.indexOf("?error=") + 7));
      viewContext.notification.show(msg, "error");
    }
  }, [location.search, viewContext.notification]);

  return (
    <Animate type="pop" className="bg-yellow">
      <Seo title="Sign In" description="Sign in to Animatr" />
      <div className={stl.signInContainer}>
        <Row
          title="Sign In to Animatr"
          fontCss
          customeStyle={{
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: "30px",
            color: "#231F20",
          }}
          align="left"
        >
          <Card center className="mt-10 mb-12 form-width form-border max-w-2xl">
            <Form
              data={form}
              method="POST"
              url={"/api/auth"}
              buttonText={"Sign In"}
              buttonColor={"#FF00D4"}
              callback={(res) => {
                res.data["2fa_required"]
                  ? navigate(`/signin/otp?token=${res.data.token}`)
                  : navigate(authContext.signin(res));
              }}
            />
          </Card>
          <p
            style={{
              fontFamily: 'Poppins',
              fontWeight: '600',
              fontSize: 24,
              color: '#231F20',
              textAlign: 'center',
              marginTop: 40,
            }}
          >
            Don't have an account?
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 16,
            }}
          >
            <Button text="Register" goto="/register" outline rounded />
          </div>
        </Row>
      </div>
    </Animate>
  );
}

export default LoginPage;
