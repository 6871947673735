import React from 'react';
import { Link } from 'components/lib';

import stl from './link.module.scss'

export function FormLink(props){

  return (
    <Link 
      url={ props.url } 
      text={ props.text } 
      className={ stl.linkContainer }
    />
  );
}