import React from "react";
import { Label, Icon, ClassHelper } from "components/lib";
import Style from "./input.tailwind.js";

export function TextInput(props) {
  // let error = props.errorMessage || "Please enter a value";

  if (
    props.scriptText &&
    props.value &&
    props.value.trim().split(" ").length > 100
  ) {
    // error = "Script must be less than 100 words";
    props.onChange?.(props.name, props.value, false);
  }

  function validate(e) {
    let value = e ? e.target.value : "";
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === "") valid = false;

    // input isn't required and value is blank
    if (!props.required && value === "") valid = true;

    if (props.required && value !== "") valid = true;

    // update the parent form
    props.onChange?.(props.name, value, valid);
  }

  // style
  const textStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
    // select: true,
  });

  return (
    <div className={Style.input}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}

      {props.type === "textarea" ? (
        <textarea
          rows="6"
          id={props.id}
          name={props.name}
          value={props.value}
          className={textStyle}
          placeholder={props.placeholder}
          onChange={(e) => {
            props.onChange?.(props.name, e.target.value, undefined);
            validate(e);
          }}
          onBlur={(e) => validate(e)}
        ></textarea>
      ) : (
        <input
          type="text"
          id={props.id}
          name={props.name}
          value={props.value || ""}
          className={textStyle}
          placeholder={props.placeholder}
          onChange={(e) =>
            props.onChange?.(props.name, e.target.value, undefined)
          }
          onBlur={(e) => validate(e)}
        />
      )}

      {/* {props.valid === false && <Error message={error} />} */}

      {props.valid === true && (
        <Icon
          image="check"
          color="#8CC57D"
          className={Style.successIcon}
          size={16}
        />
      )}
    </div>
  );
}
