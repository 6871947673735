/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment, useContext } from "react";
import {
  AuthContext,
  Form,
  Card,
  Animate,
  useAPI,
  AccountNav,
} from "components/lib";

export function Profile(props) {
  // const navigate = useNavigate();

  // context
  const authContext = useContext(AuthContext);
  // const viewContext = useContext(ViewContext);

  // fetch
  const user = useAPI("/api/user");

  // function closeAccount() {
  //   viewContext.modal.show(
  //     {
  //       title: "Close Your Account",
  //       form: {},
  //       buttonText: "Close Account",
  //       url: authContext.permission.owner ? "/api/account" : "/api/user",
  //       method: "DELETE",
  //       destructive: true,
  //       text:
  //         "Are you sure you want to delete your account? " +
  //         "You will lose all of your data and this can not be undone.",
  //     },
  //     () => {
  //       // destory user
  //       Event("closed_account");
  //       localStorage.clear();
  //       navigate("/signup");
  //     }
  //   );
  // }

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card title="Profile information" loading={user.loading} restrictWidth className="rounded-xl">
          {user?.data && (
            <Form
              buttonText="Save Changes"
              url="/api/user"
              method="PATCH"
              data={{
                name: {
                  label: "Your Name",
                  type: "text",
                  required: true,
                  value: user.data.name,
                  class: "rounded-lg",
                  errorMessage: "Please enter your name",
                },
                email: {
                  label: "Email address",
                  type: "email",
                  required: true,
                  value: user.data.email,
                  class: "rounded-lg",
                  errorMessage: "Please enter your email address",
                },
              }}
              callback={(res) => {
                // update the account name
                if (authContext.user?.accounts?.length > 0) {
                  const accounts = [...authContext.user.accounts];
                  accounts[
                    accounts.findIndex(
                      (x) => x.id === authContext.user.account_id
                    )
                  ].name = res.data.data.account_name;
                  authContext.update({ accounts: accounts });
                }

                // update the user name
                authContext.update({ name: res.data.data.name });
              }}
              buttonColor={'#FF00D4'}
            />
          )}
        </Card>
      </Animate>
    </Fragment>
  );
}
