import React, { useCallback } from 'react'
import { Button, useNavigate } from 'components/lib'

const NoCredits = (): JSX.Element => {
  const navigate = useNavigate();

  const handleNavigateToBilling = useCallback(() => {
    navigate('/account/billing')
  }, [navigate])

  return (
    <div className="container p-4">
      <div className="row">
        <div className="col-md-12">
          <div className="mt-4 mb-4 text-center">
            <h1 className="text-3xl font-bold text-black mb-4 text-center">
              No credits left!
            </h1>
            <p className="text-center">You've used up all your credits.</p>
            <p className="text-center">To continue, you will have to purchase additional credits.</p>
            <br /><br />
            <Button
              action={() => handleNavigateToBilling()}
              text="Purchase Credits"
              rounded
              className="block mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoCredits
