import React, {useEffect, useContext} from 'react';
import {AuthContext} from 'components/lib';

const Settings = require('settings.json');

const VersionChecker = (): JSX.Element => {
  const context = useContext(AuthContext);

  useEffect(() => {
    if(context){
      const latestVersion = Settings.global.latestRelease;
      const localVersion = localStorage.getItem('last-update');

      if (!localVersion || localVersion !== latestVersion) {
        context.signout();
        localStorage.removeItem('user');
      }

      localStorage.setItem('last-update', latestVersion);
    }
  }, [context]);

  return <></>
}

export default VersionChecker;
