/***
 *
 *   FOOTER (homepage)
 *   Static homepage footer
 *
 **********/

import React from "react";
import { Animate, Row, Content } from "components/lib";
import FooterLogo from "../logo/images/logo-footer.webp";
import Style from "./footer.tailwind.js";

export function Footer(props) {

  return (
    <Animate type="slideup" className={Style.footer}>
      <div className={Style.footer}>
        <footer
          className={Style.footer}
          style={{
            bottom: 0,
            // marginTop: "10%",
          }}
        >
          <Row align="center" className="bg-pink">
            <Content>
              <nav className={Style.nav}>
                <div className={Style.logo}>
                  <img src={FooterLogo} alt="logo" className="m-auto" />
                </div>
                <br />
                <span className={Style.title}>
                  Animate Like It's {new Date().getFullYear()}.
                </span>

                {/* <Link url="/" text="Home" className={Style.link} color="dark" />
              <Link
                url="/pricing"
                text="Pricing"
                className={Style.link}
                color="dark"
              />
              <Link
                url="/signin"
                text="Sign in"
                className={Style.link}
                color="dark"
              />
              <Link
                url="/signup"
                text="Sign up"
                className={Style.link}
                color="dark"
              />
              <Link
                url="/terms"
                text="Terms"
                className={Style.link}
                color="dark"
              />
              <Link
                url="/privacy"
                text="Privacy"
                className={Style.link}
                color="dark"
              />
              <Link
                url="/contact"
                text="Contact"
                className={Style.link}
                color="dark"
              /> */}
              </nav>
              <span className={Style.subtitle}>
                Turn any text based content into <br /> enchanting animation.
              </span>
            </Content>
          </Row>
          <address className={Style.address}>
            © {new Date().getFullYear()}. Animatr. ALL RIGHTS RESERVED.
          </address>
        </footer>
      </div>
    </Animate>
  );
}
