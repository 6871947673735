/***
 *
 *   FOOTER (homepage)
 *   Static homepage footer
 *
 **********/

import React from "react";
// import { Animate, Row, Content } from "components/lib";
import { Animate } from "components/lib";
// import FooterLogo from "../logo/images/footerLogo-light.png";
import Style from "./footer.tailwind.js";

export function PlayGroundFooter(props) {
  return (
    <Animate type="slideup">
      <address className={Style.playgroundfooter}>
        © {new Date().getFullYear()}. Animatr. ALL RIGHTS RESERVED.
      </address>
    </Animate>
  );
}
