/***
 *
 *   LOADER
 *   Infinite spinning animation for loading states
 *
 **********/

import React from "react";
// import Orbit from "./images/orbit.svg";
import loader from "./images/loading.png";
import { ClassHelper } from "components/lib";
import Style from "./loader.tailwind.js";

export function Loader(props) {
  const loaderStyle = ClassHelper(Style, props);

  return (
    <div className={loaderStyle}>
      <img src={loader} className={Style.orbit} alt="Orbit Spinner" />
    </div>
  );
}
