import React, { useState, useCallback, useEffect } from "react";
import stl from "../avtarcard.module.scss";
import stephenAvatar from "../images/blackgirl.png";
import ruthAvatar from "../images/girlwhairband.png";
import salliAvatar from "../images/salliAvatar.png";
import justinAvatar from "../images/justinAvatar.png";
import SliderCard from "../slidercard";
import { Select } from '../../form/select/select';

const avatr = [
    {
        id: "Salli",
        image: salliAvatar,
        voice: "https://d1.awsstatic.com/product-marketing/Polly/voices/features_salli_neural.c8e257382af31ffce5e974b34e2d41b9605f17e4.mp3",
    },
    {
        id: "Stephen",
        image: stephenAvatar,
        voice: "https://d1.awsstatic.com/product-marketing/Polly/voices/Stephen_sample.9dcc1b127f225f7c15e6b3da800b362101a95403.mp3",
    },
    {
        id: "Ruth",
        image: ruthAvatar,
        voice: "https://d1.awsstatic.com/product-marketing/Polly/voices/Ruth_sample.ac0a5ebb0238fcda47d5a0380a592ea42fea0de1.mp3",
    },
    {
        id: "Justin",
        image: justinAvatar,
        voice: "https://d1.awsstatic.com/product-marketing/Polly/voices/features_justin_neural.2829d189944815a0b0db02f4d36e8bf7043c9445.mp3",
    },
];

export function VoiceSelectorStep(props) {
    const [slidecurrentPlaying, setSlidecurrentPlaying] = useState();

    const handleSlideCurrentPlaying = (id) => {
        setSlidecurrentPlaying(id);
    };

    const handleSlideSelectedAvatar = (id) => {
        props.setFormData({ ...props.formData, selectSlideAvatar: id });
    };

    //#region handle language change

  const handleLanguageChange = useCallback((newLanguage) => {
    props.setFormData({ ...props.formData, chooseLanguage: newLanguage });
  }, [props])

  useEffect(() => {
    if (props.formData.chooseLanguage === '') {
      props.setFormData({ ...props.formData, chooseLanguage: 'en' });
    }
  }, [props.formData.chooseLanguage, props])

  const options = [
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'fr',
      label: 'French',
    },
    {
      value: 'es',
      label: 'Spanish',
    },
    {
      value: 'de',
      label: 'German',
    },
    {
      value: 'nl',
      label: 'Dutch',
    },
    {
      value: 'pl',
      label: 'Polish',
    },
  ];

  //#endregion

    return (
        <div>
            <div className={stl.headingContainer}>
                <div>
                    <div
                        style={{
                            width: "100%",
                            color: "#000",
                            fontSize: "24px",
                            fontWeight: "600",
                        }}
                    >
                        (3/3) Pick a voice-over style
                    </div>
                    <div
                        style={{
                            width: "100%",
                            textJustify: "auto",
                            color: "#6C757D",
                            fontSize: 16,
                            marginBottom: 16,
                        }}
                    >
                        Hover over the avatars and listen to their voices. Then click the one you prefer to hear as voice-over. Your video comes with the voice
                        only, you will not see the image of the talking avatar.
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        gap: "20px",
                        flexWrap: "wrap",
                    }}
                >
                    {avatr &&
                        avatr.map((slideitem, i) => {
                            return (
                                <SliderCard
                                    key={slideitem.id}
                                    slideitem={slideitem}
                                    slidecurrentPlaying={slidecurrentPlaying}
                                    handleSlideCurrentPlaying={handleSlideCurrentPlaying}
                                    selectSlideAvatar={props.formData.selectSlideAvatar}
                                    handleSlideSelectedAvatar={handleSlideSelectedAvatar}
                                />
                            );
                        })}
                </div>

                {
                    // language selector moved here
                }
                <div style={{ paddingTop: 24 }}>
                    <Select
                        label="Choose language"
                        description="This will be the language of your video's voice-over."
                        options={options}
                        default={props?.formData?.chooseLanguage}
                        callback={(newLanguage) => handleLanguageChange(newLanguage)}
                        onChange={(newLanguage) => handleLanguageChange(newLanguage)}
                        required
                    />
                </div>
            </div>
        </div>
    );
}
