import { NarrativeDescriptionStep } from './narrativeDescription';
import { VideoDescriptionStep } from './videoDescription';
import { VoiceSelectorStep } from './voiceSelector';

export function AvatarCardStep(props) {
  switch (props.currentStep) {
    case 1:
      return (
        <VideoDescriptionStep
          formData={props.formData}
          setFormData={props.setFormData}
          isPaidUser={props?.isPaidUser}
          user={props.user}
          onNextStep={props.onNextStep}
        />
      );

    // case 2:
    //   return (
    //     <NarrativeDescriptionStep
    //       formData={props.formData}
    //       setFormData={props.setFormData}
    //       isPaidUser={props?.isPaidUser}
    //       user={props.user}
    //     />
    //   );

    case 2:
      return (
        <VoiceSelectorStep
          formData={props.formData}
          setFormData={props.setFormData}
        />
      );

    default:
      return (
        <VideoDescriptionStep
          formData={props.formData}
          setFormData={props.setFormData}
          isPaidUser={props?.isPaidUser}
        />
      );
  }
}
