import React from 'react';
import stl from './label.module.scss'

export function Label(props) {


  return (
    <label className={stl.labelText} htmlFor={props.for}>
      {props.text}
    </label>
  );
}