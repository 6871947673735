import { useEffect, useMemo } from 'react';
import { Icon } from 'components/lib';
import {
  backgroundColorByType,
  backgroundIconByType,
  iconByType,
  iconColorByType,
} from './util';
import Style from './toastMessage.tailwind';

const ToastMessage = ({ message, onRemoveMessage }) => {
  const bgColor = useMemo(
    () => backgroundColorByType(message.type),
    [message.type]
  );

  const bgIcon = useMemo(
    () => backgroundIconByType(message.type),
    [message.type]
  );

  const iconColor = useMemo(
    () => iconColorByType(message.type),
    [message.type]
  );

  const icon = useMemo(() => iconByType(message.type), [message.type]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onRemoveMessage(message.id);
    }, message.duration || 7000);

    return () => {
      clearTimeout(timeout);
    };
  }, [message, onRemoveMessage]);

  const handleRemoveToast = (event) => {
    onRemoveMessage(message.id);
    event.stopPropagation();
  };

  const handleToastClicked = () => {
    onRemoveMessage(message.id);
    message.onClick && message.onClick();
  };

  return (
    <div
      onClick={handleToastClicked}
      className={Style.container}
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div className={Style.contentContainer}>
        <div
          className={Style.iconContainer}
          style={{
            backgroundColor: `${bgIcon}66`,
          }}
        >
          {icon}
        </div>
        <div>
          {!!message.title && (
            <strong className={Style.title}>{message.title}</strong>
          )}
          {!!message.text && <p>{message.text}</p>}
        </div>
      </div>
      <button onClick={handleRemoveToast} className={Style.closeButton}>
        <Icon color={iconColor} image={'x'} size={24} />
      </button>
    </div>
  );
};

export default ToastMessage;
