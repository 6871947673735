import React, {useState, useEffect, useContext} from "react";
import {Animate, AuthContext, Button, Seo} from "components/lib";
import Confetti from "../../../components/logo/images/confetti.svg";
import Axios from "axios";
import stl from './waiting.module.scss'
import { getEnv } from "app/util";
import ConfettiParticles from "components/particles/Confetti";
const Settings = require('../../../../src/settings.json')

let timeInterval = null;

export function Waiting() {
  const [url, setUrl] = useState('')
  const [progressText, setProgressText] = useState('')
  const userContext = useContext(AuthContext);
  console.log('what is user', userContext)
  const validateUrl = (url) => {
    if (url) {
      // eslint-disable-next-line no-useless-escape
      let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      var regex = new RegExp(expression);
      if (url.match(regex)) {
        return true;
      } else {
        return false;
      }

    }
  }

  const sendPartialRegistrationRequest = async (email, firstname, lastname, url) => {
    const baseUrl = Settings[getEnv()].server_url;
    await Axios({
      method: 'POST',
      url: `/api/partial-user`,
      baseURL: `${baseUrl}`,
      data: {
        "email": email,
        "firstname": firstname,
        "lastname": lastname,
        "url": url
      }
    });
  }
  const getProgress = async () => {
    let params = new URLSearchParams(window.location.search);
    let uniqueId = params.get('uniqueid');
    const baseUrl = Settings[getEnv()].video_server_url;
    let res = await Axios({
      method: 'GET',
      url: `get_progress?uniqueid=${uniqueId}`,
      baseURL: `${baseUrl}`,
    });
    let url = res.data;
    if (validateUrl(url)) {
      let firstname = params.get('firstname');
      let lastname = params.get('lastname');
      let email = params.get('email');
      if (firstname && lastname && email) {
        sendPartialRegistrationRequest(email, firstname, lastname, url)
      }

      setUrl(res.data)
    } else {
      setProgressText(res.data)
    }
  }
  useEffect(() => {
    if (url.length && timeInterval) {
      clearInterval(timeInterval)
    }
  }, [url])

  useEffect(() => {
    let chromeUrl = window.location.href.split("url=")[1];
    if (validateUrl(chromeUrl)) {
      setUrl(chromeUrl)
    } else {
      if (url.length && timeInterval) {
        clearInterval(timeInterval)
      } else {
        // Disabled because of the that url not used anymore.
        /** if (!timeInterval) {
          getProgress()
          timeInterval = setInterval(() => {
            getProgress()
          }, 7000)
        } */
      }
    }
    return () => {
      clearInterval(timeInterval)
      timeInterval= null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Animate type="pop">
      <Seo
        title="Check Mail"
        description="received animatr video in your mail"
      />
      <div className={stl.container}>

        <p className={stl.headingText}>
          Yay! You'll receive your Animatr video in a few minutes.
        </p>

        <p className={stl.descriptionText}>Check your inbox and take a peek at your spam folder too.</p>

        <div className="text-center flex justify-center py-8">
          <img src={Confetti} alt="party" className="w-56 h-56"></img>
        </div>

        <p className={stl.headingText}>It takes about 10 minutes to deliver a 10 seconds video</p>

        <div style={{width: '100%', borderWidth: 0.5, borderColor: 'black', maxWidth: 941, marginTop: 40, marginBottom: 40}}></div>

        <p className={stl.descriptionText}>
          Keep experimenting! Enjoy your creative journey with our AI video tool.
        </p>
        {/* {
          url ?
            <p className={stl.linkText} onClick={()=>{
              window.open(url);
            }}>
              {url}
            </p> :
            <p className={stl.progressText}>
              {progressText}..
            </p>
        } */}
        <Button
            rounded
            color={"transparent"}
            outline
            customWidth
            text="Create New Video"
            className="mx-auto mb-5 mt-6"
            goto={userContext?.user ? "/dashboard":  "/register"}
        />

        <ConfettiParticles />
      </div>
    </Animate>
  );
}
