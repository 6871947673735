const Style = {
  footer:
    "bg-footer-bg border-solid border-slate-100 w-full",
  copyright: "block opacity-70 text-center text-sm mb-3",
  address: "block text-center text-yellow-500 text-sm not-italic py-4 bg-black",
  logo: "w-full mb-8 footer-logo",
  title: "text-5xl font-bold text-black mt-4",
  subtitle: "font-bold text-black w-3 text-center text-xl",
  nav: "mb-3",
  button:
    "mt-4 text-pink-500 font-bold  rounded-full border-2 border-pink border-solid py-1 px-5",
  link: "mr-4 text-sm no-underline hover:underline hover:text-blue-500",
  playgroundfooter:
    "block text-center text-black text-sm not-italic py-4 bg-white-fade pl-footer",
};

export default Style;
