import React from "react";
import { Animate, Avtarcard, Link, Seo } from "components/lib";

import stl from "./createVideo.module.scss";

export function Createfreevideo(props) {
    let route = window.location.href;
    return (
        // animation might not work with the chat interface {/*<Animate type="pop">*/}
        <>
            <Seo title="Create a free Animatr Video" description="Create a free animatr video" />

            <div className="flex flex-col justify-center items-center min-h-[calc(100vh-300px)] p-4 md:p-20">
                {
                    // Tagline
                }
                <div className="flex-col justify-center items-center pb-4 md:pb-12 text-center hidden md:flex">
                    <p className="font-poppins font-extrabold text-md md:text-3xl md:leading-[45px] text-[#231f20]">Create your {route.includes("free") && "free"}</p>
                    <p className="font-poppins font-extrabold text-xl md:text-5xl md:leading-[50px] text-[#231f20]">Animatr video!</p>
                </div>

                {
                    // Flow
                }
                <Avtarcard
                    restrictWidth
                    center
                    className=""
                    title={"Choose the Voice of the voice-over "}
                    label1={"Your avatar comes with a voice, click the audio icon in the upper right side of the picture to listen to it the avatar's voice."}
                    title2={"Create your script for the video :"}
                    label2={"You can type or paste your text of max. 100 words , which will be transformed into a video"}
                    goto="/send-video"
                    free
                />
                {
                    //#region sign in link
                }
                {/* Not needed as this page will be behind singin in the future
                
                <p
                  style={{
                    paddingTop: 40,
                    textDecoration: 'underline',
                    fontWeight: '600',
                  }}
                >
                  Already Registered?{' '}
                  <Link className={stl.linkContainer} url="/signin" text="Sign In" />
                </p>

                
                */}
                {
                    //#endregion sign in link
                }
            </div>
        </>
        //{/*</Animate>*/}
    );
}
