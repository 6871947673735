import React, { useEffect, useState, useRef } from "react";
import Sound from "../logo/images/darksound.png";
import ColorSound from "../logo/images/coloraudioicon.png";
import stl from "./avtarcard.module.scss";

const SliderCard = ({
  slideitem,
  slidecurrentPlaying,
  handleSlideCurrentPlaying,
  selectSlideAvatar,
  handleSlideSelectedAvatar,
  handleSelectedAvatar,
}) => {
  const myRef = useRef();
  const [showPlay, setShowPlay] = useState(false);

  const startAudio = () => {
    myRef.current.play();
    handleSlideCurrentPlaying(slideitem && slideitem.id);
    setShowPlay(true);
  };

  const pauseAudio = () => {
    myRef.current.pause();
    setShowPlay(false);
  };

  useEffect(() => {
    if (slidecurrentPlaying !== slideitem.id) {
      pauseAudio();
    }

    myRef.current.onended = () => {
      setShowPlay(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slidecurrentPlaying]);

  return (
    <div onClick={() => {
      try {
        myRef.current.play();
        handleSlideCurrentPlaying(slideitem && slideitem.id);
        setShowPlay(true);
        handleSlideSelectedAvatar(slideitem.id);
        handleSelectedAvatar && handleSelectedAvatar("");
      }catch(e) {
        console.log(e, "THIS IS ERROR")
      }
    }} className={slideitem.id === selectSlideAvatar ? stl.imageContainerActive : stl.imageContainer} style={{ backgroundImage: "url('" + slideitem.image + "')" }} >
      <div className={stl.volumneIcon} onClick={startAudio}>
        <audio ref={myRef}  src={slideitem && slideitem.voice} />
        <img
          src={showPlay ? ColorSound : Sound}
          alt="sound"
          className={stl.volumeIconImage}
        />
      </div>
    </div>
  );
};

export default SliderCard;
