import Chat from "components/chat/chat";
import { Link } from "components/lib";
import React, { useCallback, useEffect } from "react";

export function VideoDescriptionStep(props) {

    //#region Upgrade plan

    // No premium plan support in the chat yet

    const upgradePlanLink = props.user ? "/account/billing" : "/register";
    const maxCharSubtitle = props?.isPaidUser ? "Max 400 characters = up to 30 sec length video" : "Max 67 characters = max 5 seconds long video";

    //#endregion

    const onChatJsonResult = (result) => {
        console.log("chat result: data:", result);

        if (!result) {
            console.error("chat result: result is empty")
        }

        const voiceMessage = result.narration;
        const promptMessage = result.prompts[0];
        const promptMessage2 = result.prompts[1];
        const promptMessage3 = result.prompts[2];

        const sanitizedVoiceMessage = voiceMessage.replace(/[^a-zA-Z0-9 .,?!%&()-_+=:;]/giu, "");
        props.setFormData({
            ...props.formData,
            promptMessage: promptMessage,
            promptMessage2: promptMessage2,
            promptMessage3: promptMessage3,
            voiceMessage: sanitizedVoiceMessage,
        });

        if (props.onNextStep) {
            // 5 sec delay so last message can be read
            setTimeout(() => {
                props.onNextStep();
            }, 5000);
        }
    };

    return <Chat onFinalResult={onChatJsonResult} />;

}
