import { Icon } from 'components/lib';

export const backgroundColorByType = (type) => {
  switch (type) {
    case 'warning':
      return '#FFFDCE';

    case 'error':
      return '#FDF1F5';

    case 'success':
      return '#DDFFF4';

    default:
      return '#DDFFF4';
  }
};

export const backgroundIconByType = (type) => {
  switch (type) {
    case 'warning':
      return '#fff200';

    case 'error':
      return '#ff1919';

    case 'success':
      return '#00ffb0';

    default:
      return '#00ffb0';
  }
};

export const iconColorByType = (type) => {
  switch (type) {
    case 'warning':
      return '#854D0E';

    case 'error':
      return '#940000';

    case 'success':
      return '#00563B';

    default:
      return '#00563B';
  }
};

export const iconByType = (type) => {
  switch (type) {
    case 'warning':
      return (
        <Icon color={iconColorByType(type)} image={'alert-circle'} size={24} />
      );

    case 'error':
      return (
        <Icon color={iconColorByType(type)} image={'x-circle'} size={24} />
      );

    case 'success':
      return (
        <Icon color={iconColorByType(type)} image={'check-circle'} size={24} />
      );

    default:
      return (
        <Icon color={iconColorByType(type)} image={'check-circle'} size={24} />
      );
  }
};
