const Style = {
  input: "relative mb-6 last:mb-0 input-container ",
  textbox: `relative block w-full p-3 rounded border-1 border-grey focus:border-pink bg-yellow-fade  appearance-none disabled:opacity-50 input-box border border-solid`,

  cardbox: "!p-4",

  success: "border border-solid border-emerald-500",
  successIcon: "absolute top-[4.7em] right-3",
  error:
    "relative text-red-500 mb-0 border border-solid border-red-500 bg-red-200 placeholder:text-red-600",
  warning: "bg-orange-50 border border-solid border-orange-500",
};

export default Style;
