import { useCallback, useEffect, useState } from 'react';
import ToastMessage from '../ToastMessage';
import Style from './toastContainer.tailwind';

const ToastContainer = () => {
  const [messages, setMessages] = useState([]);

  const handleRemoveMessage = useCallback((id) => {
    setMessages((prevState) =>
      prevState.filter((message) => message.id !== id)
    );
  }, []);

  useEffect(() => {
    const handleAddToast = (event) => {
      const { type, title, text, onClick, duration } = event.detail;

      setMessages((prevState) => [
        ...prevState,
        { id: Math.random(), type, title, text, onClick, duration },
      ]);
    };

    document.addEventListener('addtoast', handleAddToast);

    return () => {
      document.removeEventListener('addtoast', handleAddToast);
    };
  }, []);

  return (
    <div className={Style.container}>
      {messages.map((message) => (
        <ToastMessage
          key={message.id}
          message={message}
          onRemoveMessage={handleRemoveMessage}
        />
      ))}
    </div>
  );
};

export default ToastContainer;
