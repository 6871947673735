import React, { useContext, useState, useMemo } from "react";
import { useNavigate, Button, useAPI, AuthContext } from "components/lib";
import Axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { v4 as uuidv4 } from "uuid";
import { AvatarCardStep } from "./steps";
import { toast } from "app/util";
import Unauthenticated from "./states/unauthenticated/unauthenticated";
import NoPlan from "./states/no-plan/no-plan";
import NoCredits from "./states/no-credits/no-credits";
const Settings = require("../../../src/settings.json");

export function Avtarcard(props) {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        promptMessage: "",
        promptMessage2: "",
        promptMessage3: "",
        voiceMessage: "",
        chooseLanguage: "",
        selectSlideAvatar: undefined,
    });
    const steps = 2;
    const user = useAPI("/api/user");
    const context = useContext(AuthContext);
    const credits = context?.user?.credits ?? 0;

    const userState = useMemo(() => {
      let state = 'unauthenticated';
  
      if(context?.user && context.user.authenticated === true){
        if(!context.user.plan || context.user.plan === null || typeof context.user.plan === 'undefined'){
          state = 'no-plan'
        } else if(context.user.credits <= 0){
          state = 'no-credits'
        } else{
          state = 'available'
        }
      }
  
      return state;
    }, [context])
  
    const createVideoAction = async () => {
      let uniqueId = uuidv4();
  
      if (!formData.selectSlideAvatar) {
        toast({
          type: 'error',
          title: 'Please Select an Avatar!',
          text: '',
          onClick: () => null,
        });
  
        return;
      }
  
      if(userState !== 'available'){
        let errorMessage = ''
  
        if(userState === 'unauthenticated'){
          errorMessage = 'Please login to continue.'
        }else if(userState === 'no-plan'){
          errorMessage = 'Please select a plan to continue.'
        }
  
        toast({
          type: 'error',
          title: "I'm afraid we can't let you do that",
          text: errorMessage,
          onClick: () => null,
        });
  
        return;
      }

        if (props.goto === "/send-video") {
            console.log("createVideoAction: send video callback...", formData);
            navigate(
                `${props.goto}?uniqueid=${uniqueId}&avatar=${formData.selectSlideAvatar}&promptMessage=${formData.promptMessage}&promptMessage2=${formData.promptMessage2}&promptMessage3=${formData.promptMessage3}&voiceMessage=${formData.voiceMessage}&chooseLanguage=${formData.chooseLanguage}`
            );
        } else {
            console.log("createVideoAction: calling video api directly...", formData);
            const baseUrl = Settings[process.env.NODE_ENV].video_server_url;
            let promptMessage = [formData.promptMessage, formData.promptMessage2, formData.promptMessage3];
            let userInputData = {
                uniqueId: uniqueId,
                avatar: formData.selectSlideAvatar,
                promptMessage: promptMessage,
                voiceMessage: formData.voiceMessage,
                firstname: user.data.name,
                lastname: "",
                email: user.data.email,
                chooseLanguage: formData.chooseLanguage,
                authenticated: true,
                accountId: context.user.account_id ?? undefined,
            };
            Axios({
                method: "POST",
                url: `api/video/generateVideoWithSound`,
                baseURL: `${baseUrl}`,
                data: userInputData,
                headers: {
                    "Content-Type": "application/json",
                    //Connection: "keep-alive", // Browsers don't allow setting this header for security reasons
                },
            });
            navigate(`/inprogress?uniqueid=${uniqueId}&avatar=${formData.selectSlideAvatar}`);
        }
    };

    const handleContinueWithoutCredit = () => {
        toast({
            type: "error",
            title: "Low credits",
            text: "Please purchase credits to create more videos",
            onClick: () => navigate("/account/billing"),
        });
    };

    const handleContinueButtonPress = () => {
        if (props.user && credits === 0) {
            handleContinueWithoutCredit();
            return;
        }

        currentStep === steps ? createVideoAction() : setCurrentStep((prevState) => (prevState < 3 ? prevState + 1 : prevState));
    };

    return (
      // w-90 w-5/6 
        <div className="max-w-2xl bg-white rounded-lg">
          {userState === 'unauthenticated' && (
            <Unauthenticated />
          )}
          {userState === 'no-plan' && (
            <NoPlan name={context?.user?.name ?? ''} />
          )}
          {userState === 'no-credits' && (
            <NoCredits />
          )}
            {
                // PROGRESS BAR
            }
            {userState === 'available' && (
              <>
            <div className="rounded-t-lg bg-slate-300 h-2 md:h-3 w-full">
                <div className={`${(currentStep == steps) ? `rounded-t-lg` : `rounded-tl-lg`} bg-black h-2 md:h-3`} style={{ width: `${(currentStep / steps) * 100}%` }} />
            </div>

            <div className="md:p-12 p-4 flex flex-col">
                {
                    // STEPS
                }
                <div>
                    <AvatarCardStep
                        steps={steps}
                        currentStep={currentStep}
                        formData={formData}
                        setFormData={setFormData}
                        userHasCredit={props?.userHasCredit}
                        isPaidUser={props?.isPaidUser}
                        user={props.user}
                        onNextStep={handleContinueButtonPress}
                    />
                </div>
                {
                    // STEP BUTTONS
                }
                {currentStep !== 1 && (
                    <div className="flex justify-between items-center pt-10 pb-10 flex-wrap gap-10">
                        <Button
                            outline
                            customWidth
                            action={() => setCurrentStep((prevState) => (prevState > 1 ? prevState - 1 : prevState))}
                            text={"Previous"}
                            rounded
                        />
                        <Button customWidth action={handleContinueButtonPress} text={currentStep === steps ? "Create video" : "Continue"} rounded />
                    </div>
                )}
            </div>
            </>
            )}
        </div>
    );
}
