/***
 *
 *   RESET PASSWORD
 *   User can set a new password using the token
 *
 **********/

import React, { useContext } from "react";
import {
  Animate,
  Row,
  AuthContext,
  Card,
  Form,
  Seo,
  useNavigate,
  useLocation,
} from "components/lib";

import stl from './authentication.module.scss'

export function ResetPassword(props) {
  // context
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(AuthContext);

  // check for token
  const qs = location.search;
  const token = qs.substring(qs.indexOf("?token=") + 7);

  return (
    <Animate type="pop">
      <Seo title=" Reset Password" description="reset your password" />
      <Row
        title="Reset Your Password"
        fontCss
        customeStyle={{ 
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 800,
          fontSize: "30px",
          color: "#231F20"
        }}
      >
        <Card center className="mt-10 mb-12 form-width form-border">
          <Form
            className={stl.formContainer} 
            data={{
              jwt: {
                type: "hidden",
                value: token,
              },
              email: {
                label: "Email",
                type: "email",
                class: "rounded-lg",
              },
              password: {
                label: "New Password",
                type: "password",
                required: true,
                class: "rounded-lg",
                complexPassword: true,
              },
              confirmPassword: {
                label: "Confirm Password",
                type: "password",
                required: true,
                class: "rounded-lg",
                complexPassword: true,
              },
            }}
            url="/api/auth/password/reset"
            method="POST"
            buttonText="Set New Password"
            callback={(res) => {
              res.data["2fa_required"]
                ? navigate(`/signin/otp?token=${res.data.token}`)
                : context.signin(res);
            }}
          />
        </Card>
      </Row>
    </Animate>
  );
}
