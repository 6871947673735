import { Button, Seo } from "components/lib";
import React from "react";
import { Player } from "video-react";
import "video-react/dist/video-react.css"; // import css

import stl from './playVideo.module.scss'

function PlayVideoComp(props) {
  return (
    <div className="flex align-center justify-column mt-10 mb-4 gap-20 container-padding" >
      <Seo
        title="Preview Your Video"
        description="check out your video in your mail"
      />
      <Player
        // className="w-16 md:w-48 lg:w-64"
        autoPlay={true}
        fluid={false}
        width={994}
        height={559}
        src={props.url}
      ></Player>
      {
        !props.hideText ?
      <div className={stl.textContainer}>
        <span className={stl.descriptionText}>Here is your Animatr video</span>
        <span className={stl.descriptionText}>Hi Name - check out your video.</span>
      </div> : <></>
      }
      <Button
        color={"transparent"}
        outline
        customWidth
        text={props.buttonText}
        goto={props.goto}
        className={stl.buttonContainer}
      />
    </div>
  );
}

export default PlayVideoComp;
