/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  AppNav,
  AuthContext,
  Header,
  PlayGroundFooter,
  User,
  useLocation,
} from "components/lib";

import Style from "./app.module.scss";

export function AppLayout(props) {
  const context = useContext(AuthContext);
  const location = useLocation()
  const [pageName, setPageName] = useState('')

  useEffect(() => {
    const page = (location.pathname === '' || location.pathname === '/') ? '/home' : location.pathname
    const sanitizedPageName = page.replace('/', '').replace(/-/giu, ' ')
    setPageName(sanitizedPageName)
  }, [location])

  return (
    <Fragment>
      <AppNav
        items={[
          { label: "Playground", icon: "video", link: "/dashboard" },
          { label: "Profile", icon: "user", link: "/account/profile" },
          // { label: "Help", icon: "help-circle", link: "/help" },
          { label: "Sign Out", icon: "log-out", action: context.signout },
        ]}
      />

      <main className={Style.app}>
        <Header title={pageName}>
          <User />
        </Header>

        {<props.children {...props.data} />}
      </main>
      <PlayGroundFooter />
    </Fragment>
  );
}
