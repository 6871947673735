import React, { useContext } from "react";
import {
  AuthContext,
  Animate,
  Row,
  Card,
  Form,
  Link,
  Seo,
} from "components/lib";

import stl from './authentication.module.scss'

function Register() {
  const context = useContext(AuthContext);
  return (
    <Animate type="pop">
      <Seo title="Register" description="Create your account" />
      <div className={stl.signInContainer}>
        <Row
          title="Create Your Account"
          fontCss
          customeStyle={{
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: "30px",
            color: "#231F20"
          }}
        >
          <Card loading={false} center className="mt-10 mb-12 form-width form-border max-w-2xl">
            <Form
              className={stl.formContainer}
              data={{
                name: {
                  label: "First Name",
                  type: "text",
                  required: true,
                  class: "rounded-lg",
                  errorMessage: "Please enter your first name",
                },
                email: {
                  label: "Email Address",
                  type: "email",
                  class: "rounded-lg",
                  required: true,
                },
                password: {
                  label: "Password",
                  type: "password",
                  required: true,
                  class: "rounded-lg",
                  complexPassword: true,
                },
                confirm_password: {
                  type: "hidden",
                  value: null,
                },
              }}
              url="/api/account"
              method="POST"
              buttonText="Create Account"
              callback={context.signin}
              buttonColor={'#FF00D4'}
            />

          </Card>
          <div className={stl.linkDescriptive}>
            <Link className={stl.linkContainer} url="/signin" text="Already registered? Sign In" />
          </div>
        </Row>
      </div>
    </Animate>
  );
}

export default Register;
