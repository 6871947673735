/***
*
*   HEADER
*   Header section with title used in main application (can render children)
*
*   PROPS
*   title: title of the view
*   children: children to render (optional)
*
**********/

import React, {useState, useEffect} from 'react'
import Style from './header.module.scss';

export function Header(props){
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    
    window.onresize = () => {
      setWindowWidth(window.innerWidth)
    }
  }, [])

  return (
    <header className={ Style.header }>

      { props.title && windowWidth >= 350 && <h1 style={{color : '#000000', textTransform: 'capitalize'}}>{ props.title }</h1> }
      { props.children }

    </header>

  );
}
