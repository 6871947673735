import { SignupPlan } from "views/auth/loginPage/plan";
// import { SignupUser } from "views/auth/signup/user";
// import { Signin } from "views/auth/signin";
// import { SigninOTP } from "views/auth/signin/otp";
// import { SocialSignin } from "views/auth/signin/social";
// import { ForgotPassword } from "views/auth/signin/forgotpassword";
import { ResetPassword } from "views/auth/loginPage/resetpassword";
// import { MagicSignin } from "views/auth/signin/magic";
// import { ImpersonateSignin } from "views/auth/signin/impersonate";
import LoginPage from "views/auth/loginPage";
import { Createfreevideo } from "views/auth/createvideo";
import PlayVideoNoAuth from "views/auth/createvideo/playVideo";
import Register from "views/auth/loginPage/register";
import SendVideo from "views/auth/createvideo/sendVideo";
import { ForgotPassword } from "views/auth/loginPage/forgotpassword";
import { Waiting } from "views/auth/waiting";
// import Plan2 from "views/auth/loginPage/plan2";
import PlanPage from "views/PlanPage";

const Routes = [
  // ? New
  {
    path: "/signin",
    view: LoginPage,
    layout: "auth",
    title: "Login",
  },
  {
    path: "/purchase",
    view: PlanPage,
    layout: "auth",
    title: "Profile",
  },
  {
    path: "/register/plan",
    view: SignupPlan,
    layout: "auth",
    permission: "owner",
    title: "Select Plan",
  },
  {
    path: "/create-free-video",
    view: Createfreevideo,
    layout: "auth",
    title: "Create a free Animatr Video",
  },
  {
    path: "/send-video",
    view: SendVideo,
    layout: "auth",
    title: "video",
  },
  {
    path: "/preview-video",
    view: PlayVideoNoAuth,
    layout: "auth",
    title: "Preview Video",
  },
  {
    path: "/forgotpassword",
    view: ForgotPassword,
    layout: "auth",
    title: "Forgot Your Password?",
  },
  {
    path: "/register",
    view: Register,
    layout: "auth",
    title: "Sign up",
  },
  {
    path: "/inprogress",
    view: Waiting,
    layout: "auth",
    title: "Check you mail",
  },
  {
    path: "/resetpassword",
    view: ResetPassword,
    layout: "auth",
    title: "Reset Your Password",
  },
  // ! --------------------------------------------------
  // ? OLD
  // {
  //   path: "/signup/user",
  //   view: SignupUser,
  //   layout: "auth",
  //   title: "Sign up to Gravity",
  // },
  // {
  //   path: "/signin",
  //   view: Signin,
  //   layout: "auth",
  //   title: "Welcome to Gravity",
  // },
  // {
  //   path: "/signin/otp",
  //   view: SigninOTP,
  //   layout: "auth",
  //   title: "Enter verification code",
  // },
  // {
  //   path: "/signin/social",
  //   view: SocialSignin,
  //   layout: "auth",
  //   title: "Completing Sign In",
  // },
  // {
  //   path: "/magic",
  //   view: MagicSignin,
  //   layout: "auth",
  //   title: "Sign In",
  // },
  // {
  //   path: "/forgotpassword",
  //   view: ForgotPassword,
  //   layout: "auth",
  //   title: "Forgot Your Password?",
  // },
  // {
  //   path: "/resetpassword",
  //   view: ResetPassword,
  //   layout: "auth",
  //   title: "Reset Your Password",
  // },
  // {
  //   path: "/signin/impersonate",
  //   view: ImpersonateSignin,
  //   layout: "auth",
  //   title: "Sign in via Mission Control",
  // },
];

export default Routes;
