export const getEnv = () => {
    return process.env.REACT_APP_APPENV ?? process.env.NODE_ENV;
}

export const toast = ({ type, title, text, onClick, duration }) => {
  const event = new CustomEvent('addtoast', {
    detail: {
      type,
      title,
      text,
      onClick,
      duration,
    },
  });

  document.dispatchEvent(event);
};
