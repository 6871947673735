import React from "react";
import { Helmet } from "react-helmet";

export function Seo(props) {
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
    </div>
  );
}
