import React, { useCallback, useMemo } from 'react'
import { Button, useNavigate } from 'components/lib'

const NoPlan = ({name}: {name: string}): JSX.Element => {
  const navigate = useNavigate();

  const firstName = useMemo(() => {
    return name.split(' ')[0]
  }, [name])

  const handleNavigateToPlanSelection = useCallback(() => {
    navigate('/register/plan')
  }, [navigate])

  return (
    <div className="container p-4">
      <div className="row">
        <div className="col-md-12">
          <div className="mt-4 mb-4 text-center">
            <h1 className="text-3xl font-bold text-black mb-4 text-center">
              No Plan Selected
            </h1>
            <p className="text-center">You're so close {firstName}!</p>
            <p className="text-center">One last step, please select a plan to continue.</p>
            <br /><br />
            <Button
              action={() => handleNavigateToPlanSelection()}
              text="Select A Plan"
              rounded
              className="block mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoPlan
